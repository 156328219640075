﻿$green: #5ABB62;
$black: #323537;
$black-true: #000000;
$gray: #8E9091;
$white: #FFFFFF;
$gold: #D3B934;
$gray85: #515356;
$gray70: #6F7173;
$gray40: #ADAEAF;
$gray25: #CCCCCD;
$gray10: #EAEAEB;
$grayHover: #D3D3D3;
$error-red: #FF0000;

$font-color: #515356;

$app-bg-color: #F2EEEE;
$container-bg-color: #ffffff;
$container-shadow-color: #E2DEDE;

$fp-gray: #E5E5E5;
$fp-gray-dc: #DCDCDC;
$fp-gray-f0: #F0F0F0;
$fp-dark-gray: #515356;
$fp-green: #58BC64;
$fp-gold: #CEB78D;

$warning-yellow: #E2B935;
$info-blue: #4B81AE;

$loading-bar-color: #3c86db;

$brand-color-fontpoint: #5ABB62;

$brand-color-facebook: #3b5998;
$brand-color-twitter: #00aced;
$brand-color-google-plus: #dd4b39;
$brand-color-youtube: #b00;
