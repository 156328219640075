﻿@keyframes spin {
    to {
        transform: rotate(359deg) scale(-1, -1);
    }

    from {
        transform: rotate(0deg) scale(-1, -1);
    }
}

.spin {
    animation: spin 2s infinite linear;
}

@keyframes slideOutRightExtended {
    from {
        transform: translate3d(0, 0, 0);
    }

    to {
        visibility: hidden;
        transform: translate3d(150%, 0, 0);
    }
}

.slide-out-right-extended {
    animation-name: slideOutRightExtended;
}

@keyframes slideOutLeftExtended {
    from {
        transform: translate3d(0, 0, 0);
    }

    to {
        visibility: hidden;
        transform: translate3d(-150%, 0, 0);
    }
}

.slide-out-left-extended {
    animation-name: slideOutLeftExtended;
}

-webkit-keyframes pulseBig {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.15, 1.15, 1.15);
    transform: scale3d(1.15, 1.15, 1.15);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulseBig {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.15, 1.15, 1.15);
    transform: scale3d(1.15, 1.15, 1.15);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.pulseBig {
  -webkit-animation-name: pulseBig;
  animation-name: pulseBig;
}

-webkit-keyframes pulseReallyBig {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.50, 1.50, 1.50);
    transform: scale3d(1.50, 1.50, 1.50);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulseReallyBig {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.50, 1.50, 1.50);
    transform: scale3d(1.50, 1.50, 1.50);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.pulseReallyBig {
  -webkit-animation-name: pulseReallyBig;
  animation-name: pulseReallyBig;
}