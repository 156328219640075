.dispatch-container {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    margin-left: 15px;
    outline: solid;
    outline-width: 2px;
    outline-color: gray;
    margin-bottom: 15px;
}

.dispatch-instance-controls {
    float: right;
}

.dispatch-header {
    padding-bottom: 10px;
    font-weight: bold;
}

.dispatch-history{
    padding-top: 10px;
}

/* Media query for screens larger than 768px */
@media screen and (min-width: 768px) {
    .history-width-adjustment {
        width: 96%
    }
}