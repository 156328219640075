fp-emergency-edit-contact {
    display: block;

    .toolbar {
        .btn-group {
            .btn-green {
                border: 0;
            }
        }
    }

    &:not(:last-of-type) {
        margin-bottom: 20px;
        padding-bottom: 15px;
        border-bottom: 1px solid #EAEAEB;
    }
}
