.terms-header{
    justify-content: center;
    display: grid;
    margin-top: 0 !important;
}

.controls-grid{
    display: grid;
    justify-content: center;
}

.button-layout{
    display: grid;
    justify-content: center
}

.modal-content button {
    width: auto !important;
}

//prevents scrollbar from shifting modal and background
body.modal-open {
    overflow: auto !important;
}
