﻿.table {
    counter-reset: row-number;

    th {
        color: $white;
        background-color: $green;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
    }

    td {
        text-align: center;
    }

    tbody {
        tr {
            counter-increment: row-number;

            &:nth-child(odd) {
                background-color: $gray10;
            }

            &:nth-child(even) {
                background-color: $gray25;
            }

            td:first-child::before {
                content: counter(row-number) ". ";
                font-size: 20px;
                font-weight: bold;
            }
        }
    }
}