.modal-content .cancellation .modal-header {
    background-color: white;
    border: none;
}

.modal-content .cancellation .container {
    width: auto;
}

.modal-content .cancellation .primary-cancellation-button, .modal-content .cancellation .back-button {
    width: auto;
    min-height: 60px;
    font-size: large;
    text-transform: uppercase;
}

.modal-content .cancellation .back-button {
    border-color: #525356;
    background-color: transparent;
    color: #525356;
    border: 2px solid;
    border-radius: 5px;
    margin-right: 1%;
}

.modal-content .cancellation .footer {
    margin-top: 5%;
}

.modal-content .cancellation .close-modal {
    margin-top: -7px;
}

.modal-content .cancellation .close-modal i {
    color: #6a6a6a;
}

.modal-content .cancellation label {
    text-transform: uppercase;
    color: #777;
    margin-top:10px;
}

.modal-content .cancellation input[type="text"]::placeholder {
    text-align: left;
}

.modal-content .cancellation .modal-body input {
    text-align: left;
}