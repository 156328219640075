@import '../../../content/styles/site/values/_colors.scss';
@import '../../../content/styles/site/_values.scss';

.background {
    background-color: $green !important;
}

.centered-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.centered-row {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}

.header-font {
    color: white !important;
    font-weight: 600 !important;
}

.supporting-text {
    color: white !important;
    font-size: x-large !important;

    @media screen and (max-width: 767px) {
        font-size: large !important; // Adjust the font size for smaller screens
    }
}

.image-transform {
    width: 35%;

    @media screen and (max-width: 767px) {
        width: 40% !important; // Adjust the image to be larger on mobile as per mockup
    }
}
