﻿@import 'site/values';

// Variables Overrides
$main-nav-width: 240px;
$main-header-height: 68px;
$main-header-tab-height: 48px;
$main-footer-height: 28px;

$adc-route-default: #525357;
$adc-route-video: #525357;
$adc-route-activity: #e4701e;
$adc-route-automation: #e4701e;
$adc-route-users: #e4701e;
$adc-route-error: #e4701e;

@import 'adc/application';

// Style Overrides
.view-container {
    border-radius: 0;
    background: #fff;
    color: #333;
    box-shadow: 0 1px 2px 0 rgba($black, 0.2), 0 3px 6px 0 rgba($black, 0.05);
}

#app-page {
    min-height: 100vh !important;

    #app-content {
        .view-container {
            box-sizing: content-box;
            padding: 0;
            width: 100%;
        }
    }
}

#app-header-sub {
    .header-tabs.horizontal {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        justify-content: center;

        li {
            color: $white;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            font-size: 14px;
            font-weight: 300;
            height: $main-header-tab-height;
            justify-content: center;
            line-height: $main-header-tab-height;
            margin-top: 0;
            overflow: hidden;
            padding: 0;
            position: relative;
            text-align: center;
            width: auto;

            a {
                color: $white;
                height: 100%;
                line-height: inherit;
                padding: 0 16px;
                transition: box-shadow 0.3s;
                width: 100%;
                text-decoration: none;

                &:after {
                    position: absolute;
                    content: "";
                    display: block;
                    top: 0;
                    right: 0;
                    width: 16px;
                    height: 90%;
                }

                &:hover {
                    &:not(.active) {
                        box-shadow: inset 0 -4px 0 0 rgba(255,255,255,0.4);
                        overflow: visible;
                    }
                }

                &.active {
                    box-shadow: inset 0 -4px 0 0 rgba(255,255,255,0.8);
                }
            }
        }
    }
}

#app-nav {
    .nav-wrapper {
        nav {
            min-height: 65%;

            ul {
                padding-left: 0;
                color: $green !important;

                a.sub-collection-toggle.active {
                    border-bottom: 0;
                }

                ul.sub-collection {
                    /*padding-bottom: 20px;*/
                    border-top: 0px;
                    /*border-bottom: 1px solid #f0f0f0;*/

                    li a {
                        height: 30px;
                        padding-left: 72px;
                        border-bottom: 0;
                        line-height: 30px;
                        font-size: 11px;
                        text-transform: capitalize;

                        &.active {
                            color: $green;
                            border-left: 0;
                        }
                    }
                }
            }
        }
    }

    // Tablet.
    @media screen and (max-width: $break-large) {
        transform: translateX(-100%);
    }
}

#app-content {
    min-height: calc(100vh - 507px);

    .view-container {
        margin-top: 8px;
        margin-bottom: 8px;
    }
}

#app-page.header-control {
    #app-content {
        min-height: calc(100vh - 555px);
    }
}

.app-header {
    background: #313538;
}

@media (min-width: $screen-md-min) {
    #app-page {
        #app-content {
            .view-container {
                margin-left: 8px;
                margin-right: 8px;
                width: calc(100% - 16px);
            }
        }
    }
}
