.divider {
    width: 100% !important;
    margin-bottom: 3px;
    border: 1px solid #DCDCDC;
    box-sizing: border-box;
    margin-top: 10px;
    margin-bottom: 10px !important;
}

.justify-content {
    justify-content: right !important;
}

.payment-header {
    font-weight: bold;
    font-size: large;
}

.disclaimer {
    font-size: x-small;
    font-style: italic;
    margin-top: 5px;
}

.total-summary {
    font-weight: bold;
    padding-left: 0px !important;
}

.terms-header {
    font-weight: bold;
    font-size: large;
    justify-content: left;
}

.close-icon {
    vertical-align: 0px;
    padding-top: 22px !important;
}

.button-spacing {
    margin-left: 5px !important;
}

.image-size {
    max-height: 100px;
    max-width: 100px;
    width: auto;
    height: auto;
}

.guard-company{
    padding-top: 25px;
    padding-bottom: 15px;
}