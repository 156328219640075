﻿// Sizes
$font-size: 16pt;

$font-color-h1: #58bc64;
$font-color-h2: #515356;
$font-color-h3: #58bc64;
$font-color-h4: #515356;
$font-color-h5: #515356;

$font-size-h1: 30pt;
$font-size-h2: 30pt;
$font-size-h3: 18pt;
$font-size-h4: 18pt;
$font-size-h5: 16pt;

$spacing-sm: 50px;
$spacing-md: 150px;
$spacing-lg: 300px;

$icon-sm: 24px;
$icon-lg: 38px;

$grid-float-breakpoint: 990px; // makes the navbar collapse earlier
$grid-float-breakpoint-max: $grid-float-breakpoint - 1;

$screen-lg: 1200px !default;
$screen-lg-min: $screen-lg !default;

$screen-md: 992px !default;
$screen-md-min: $screen-md !default;
$screen-md-max: $screen-lg-min - 1;

$screen-sm: 768px !default;
$screen-sm-min: $screen-sm !default;
$screen-sm-max: $screen-md-min - 1;

$max-width-ipad: 768px;
$max-width-phone: 767px;
$max-width-sm-phone: 320px;