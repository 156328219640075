fp-emergency-view-contact {
    .toolbar {
        .btn-group {
            .btn-green {
                border: 0;
            }
        }
    }

    .phone {
        font-weight: normal;
    }
}