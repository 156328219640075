html,
body {
    // use !important since css scopes are leaking
    background-color: white !important;
}

.cancel-container {
    background-color: white;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.cancel-header {
    background-color: white;
    display: flex;
    height: 100px;
    align-items: center;
}

.cancel-spinner {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
}

.contact-header {
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 1rem;
    margin-bottom: 3rem;
}

.cancel-form {
    padding-bottom: 10px;
}

.contact-container {
    margin-top: 1rem;
}

.uib-datepicker-container {
    display: inline-block;
    padding: 5px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
}

.uib-datepicker {
    button {
        border-width: 1px;
    }

    thead {
        button {
            width: 100%;
        }
    }
}

.uib-timepicker {
    .form-group {
        padding-top: 0;
    }

    button {
        margin-left: 3px;
        padding: 6.5px 12px;
        border-width: 1px;
    }
}

.form-group {
    border-bottom: none !important;
}

.caption {
    font-size: 1.4rem;
    color: #999;
    padding-bottom: 8px;
}