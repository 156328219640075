@import '../../content/styles/site/values';

#app-page.login-page {
    // BEGIN: ADC Overrides
    padding-top: 0;

    #app-content,
    #app-footer {
        width: 100%;
        margin-left: 0;
    }
    // END: Adc Overrides

    $navbar-height: 60px;
    $navbar-background-color: #525357;
    $navbar-font-color: $white;

    .navbar {
        min-height: $navbar-height;
        margin-bottom: 0;
        border-radius: 0;
        border: 0;
        background-color: $navbar-background-color;
        font-size: 18px;
        font-weight: bold;

        .navbar-brand img {
            width: 150px;
            margin-top: -7.5px;
        }

        a {
            color: $navbar-font-color;
        }
    }

    .navbar-default .navbar-toggle {
        border: 0;
        padding-top: 15px;

        &:hover,
        &:focus {
            background: none;
        }

        .icon-bar {
            color: $navbar-font-color;
        }
    }

    .navbar-header {
        min-height: $navbar-height;
    }

    .login {
        min-height: calc(100vh - 653px);
        padding-top: $spacing-sm;
        padding-bottom: $spacing-sm;
        background: $green;
        color: $white;
        font-size: 14px;

        h1, h2, h3, h4, h5, h6 {
            color: $white;
        }

        a {
            color: $white;
            text-decoration: underline;

            &:hover {
                color: white;
                text-decoration: underline;
            }
        }

        .captcha {
            margin-bottom: 15px;
        }

        .btn-login {
            padding-top: 10px;
            padding-bottom: 10px;
            background: $navbar-background-color;
            color: $white;
            text-transform: uppercase;
            font-weight: bold;
        }

        .form-control {
            border: 0;
            text-align: left;
        }

        .change-password,
        .forgot-password,
        .forgot-username {
            .form-group label {
                font-weight: normal;
            }

            .alert-info {
                background-color: lightgray;
                border-color: transparent;
            }
        }

        .logo {
            margin-bottom: $spacing-sm;
            height: 100%;

            &:before {
                content: '';
                display: inline-block;
                vertical-align: middle;
                height: 100%;
            }

            img {
                display: inline-block;
                vertical-align: middle;
                width: 225px;
                margin: 0 auto;
            }
        }

        .control {
            p {
                text-align: center;
            }

            .form-group {
                label {
                    text-transform: uppercase;
                }
            }

            .information {
                margin-bottom: $spacing-sm/2;
            }

            .forgot {
                color: $white;
                text-transform: uppercase;
                margin-top: $spacing-sm/2;
            }

            .no-account{
                font-weight: bold;
            }
        }
    }

    @media (min-width: $screen-sm-min) {
        .navbar-nav > li > a {
            padding-top: 26.5px;
            padding-bottom: 14.5px;
        }

        .login {
            padding-top: $spacing-md;
            padding-bottom: $spacing-md;

            .container {
                &.change-password,
                &.forgot-password,
                &.forgot-username {
                    max-width: 500px;
                }
            }
        }
    }

    @media (min-width: $screen-md-min) {
        .login {
            .login-content {
                display: -ms-flexbox;
                flex-wrap: wrap;
            }

            .logo {
                display: -ms-flexbox;
                margin-bottom: 0;

                img {
                    display: inline-block;
                    width: 225px;
                    height: 68.25px;
                    margin: auto;
                }
            }

            .control {
                padding-top: 25px;
                padding-bottom: 25px;
                padding-left: 75px;
                border-left: 1px solid $white;

                p {
                    text-align: left;
                }
            }
        }
    }

    @media (max-width: $screen-md-min) {
        .login-header {
            .navbar {
                ul li {
                    border-bottom: 1px solid $gray70;
                }
                ul:last-child {
                    padding: 0;
                    margin-bottom: 0;
                }
            }
        }
    }
}
