html {
    background-color: white;
}

@font-face {
    font-family: 'CerebriSans-Regular';
    src: url('../../content/fonts/CerebriSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'CerebriSans-Bold';
    src: url('../../content/fonts/CerebriSans-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'OpenSans-Bold';
    src: url('../../content/fonts/OpenSans-Bold.ttf') format('truetype'), url('../../content/fonts/OpenSans-Bold.woff') format('woff'), url('../../content/fonts/OpenSans-Bold.woff2') format('woff2');
}

@font-face {
    font-family: 'OpenSans-Regular';
    src: url('../../content/fonts/OpenSans-Regular.ttf') format('truetype'), url('../../content/fonts/OpenSans-Regular.woff') format('woff'), url('../../content/fonts/OpenSans-Regular.woff2') format('woff2');
}

b {
    font-family: 'CerebriSans-Bold', 'OpenSans-Bold';
}

h2.cancellation-header {
    text-align: center;
}

.cancellation {
    background-color: white;
    height: 100%;
    color: #3e3e3e;
    font-family: 'CerebriSans-Regular', 'OpenSans-Regular';
}

.primary-cancellation-button {
    background-color: #5bbb62;
    color: white;
    border-style: none;
    padding: 10px 40px;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight:bold;
}

.primary-cancellation-button:disabled {
    background-color: #d7d7d7;
    cursor: not-allowed;
}

.small-link{
    font-size: 10px;
}

.small-link a {
    color: #5ebc64;
}

.cancellation-header {
    margin-bottom:50px;
    margin-top:35px;
}

.cancellation-visual-nav {
    font-size: 12pt;
    text-align: center;
}

.cancellation-visual-nav-section {
    padding-left: 0px;
    padding-right: 0px;
}

.cancellation-visual-nav-section hr {
    border: 3px solid #edecea
}

.cancellation-visual-nav-section-active hr {
    border: 3px solid #c3e8b5
}

.form-control[disabled] {
    color: #2e2e2e
}
