﻿@import "values/colors";

.alert {
    border-radius: 0;
}

.browserCheck {
    background-color: $white;
    min-height: 357px;
    font-size: .8em;
    color: $black;
    text-align: center;
    padding-top: 300px;
    padding-bottom: 300px;
    display: none;

    .row {
        max-width: 700px;
        margin: 0 auto;
    }

    img {
        display: block;
        padding: 10px 0;
        margin: 0 auto;
    }
}

//Compatability Checks
.ua-desktop {
    &.ua-ie-10, &.ua-ie-9, &.ua-ie-8, &.ua-ie-7, &.ua-ie-6, &.ua-ie-5,
    &.ua-safari-8, &.ua-safari-7, &.ua-safari-6, &.ua-safari-5, &.ua-safari-4 {
        .main-view {
            display: none !important;
        }

        .browserCheck {
            display: block !important;
        }

        &.overrideBrowserCheck {
            ui-view {
                display: block !important;
            }

            .browserCheck {
                display: none !important;
            }
        }
    }
}
