// just for phones
@media (max-width: 767px) {
    #offer {
        margin:4% 1%   
    }

    .bordered-offer {
        border-bottom: 1px solid black;
        margin-bottom: 15px;
    }
}

// for devices bigger than phones
@media (min-width: 768px) {
    #offer {
        margin-top: 4%
    }

    .bordered-offer {
        border-right: 1px solid black;
    }
}


#offer hr {
    border: 3px solid #5bbb64
}

#contract-agreement-text {
    overflow: auto;
    height: 200px;
    text-align: left;
}

#offer-banner p {
    text-align: center;
    padding: 0px 30px;
}

.offer-header {
    text-align: center;
    margin: 20px 0;
}

#contract-agreement {
    border: 1px solid #d7d7d7;
    padding: 15px;
}

#offer .form-group label {
    font-weight: normal;
}

#offer .form-group input {
    text-align: left;
    padding: 10px 10px;
    height: 45px;
}

.form-control[disabled] {
    color: #2e2e2e
}