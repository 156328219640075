.cancellation-complete {
    text-align: center;
    margin-top: 10%;
}

.confirmation-number {
    border: 2px solid #5abb62;
    width: fit-content;
    padding: 1%;
    display: inline-block;
    margin: 5%;
}
