@import '../../../content/styles/site/values/_colors.scss';
@import '../../../content/styles/site/_values.scss';


.breadcrumbs-component {
    padding-top: 23px;
    color: $fp-dark-gray;

    ul {
        list-style-type: none;
        padding: 0;

        li {
            display: inline-block;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: -0.51px;
            line-height: 14px;
        }

        li:last-child a {
            color: #757577;
            text-decoration: none;
        }

        li:not(:first-child)::before {
            content: ">";
            color: $fp-green;
            padding-left: 5px;
            padding-right: 5px;
        }

        li:last-child::before {
            color: #757577;
        }

        .active {
            color: $fp-green;
        }
    }

    @media(max-width: $max-width-phone) {
        text-align: center;

        ul li {
            font-size: 12px;
            letter-spacing: -0.34px;
        }
    }
}
