@import 'globals';
@import 'mixins';

#app-nav {
    .app-profile {
        width: 100%;

        .profile-tools {
            display: flex;
            align-items: center;
            justify-content: space-around;
            margin: $base-grid-2x 0;
            padding: 0 $base-grid;
            width: 100%;

            a {
                cursor: pointer;
            }

            $icon-size: $base-grid-3x;

            svg {
                &.svg-circle {
                    fill: $light-gray;
                }

                &.tool {
                    height: $icon-size;
                    width: $icon-size;
                }
            }

            .trouble-conditions-wrap {
                height: $icon-size;
                position: relative;
                width: $icon-size;

                .count {
                    font-size: $font-size-lg;
                    position: absolute;
                    right: -14px;
                    text-align: center;
                    top: -10px;
                    width: 20px;
                }
            }
        }
    }
}

// Center the links in the drop up
.popover-menu.profile-links {
    .items-list {
        li {
            a {
                text-align: center;
            }
        }
    }
}