@import '../../../content/styles/site/values/_colors.scss';
@import '../../../content/styles/site/_values.scss';

.kb-style{
    text-decoration: underline;
}

.dispatch-controls {
    padding-top: 15px;
    float: right;
}

.dispatch-instance-controls {
    display: flex;
    justify-content: end;
}

.divider {
    width: inherit;
    margin-bottom: 3px;
    border: 1px solid #DCDCDC;
    box-sizing: border-box;
}

.dispatchProtocolHelper{
    margin-right: 8px !important;
}

.dispatchProtocolHelper li {
    padding-bottom: 10px;
}

.dispatchProtocolHelperFormGroupOverride {
    margin-bottom: -25px !important;
}
.dispatchProtocolHeader{
    margin-left: 11px !important;
    font-weight: bold;
    padding-bottom: 10px;
}

.inline-select {
    display: flex;
    align-items: center;
}

.inline-select > div {
    display: inline-block;
    margin-right: 10px; /* Adjust margin as needed */
}

.select-wrapper {
    margin-right: 10px;
    display: inline-block;
}

@media (min-width:961px) { /* tablet, landscape iPad, lo-res laptops ands desktops */
    .increase-popover-width {
        max-width: 600px;
    }
}

.dispatch-header{
    padding-bottom: 10px;
    font-weight: bold;
}

.dispatch-container-upcoming{
    display: flex;
    align-items: center;
}

.dispatch-container-border {
    outline: solid;
    outline-width: 2px;
    outline-color: gray;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    margin-left: 32px;
    margin-bottom: 15px;
}

.dispatch-container-date-center{
    display: flex;
    flex-direction: column;
}

.dispatch-in-progress {
    font-weight: bold;
    color: $green;
    text-transform: uppercase;
}

.repeat-event{
    margin-top: 10px;
    margin-bottom: 5px;
}

.add-dispatch{
    margin-top: 5px;
    background: $green !important;
    color: white !important;
}

.fee-total{
    font-weight: bold;
    font-size: large;
    padding-top: 30px;
    padding-bottom: 10px;
}

.book-dispatch-button {
    background: $green !important;
    color: white !important;
}

.form-alignment {
    justify-content: right;
    display: grid;
}

.no-left-padding {
    padding-left: 0px;
}

.no-right-padding {
    padding-right: 0px;
}

.light-left-padding {
    padding-left: 5px;
}

.light-right-padding {
    padding-right: 5px;
}

.right-padding {
    padding-right: 10px;
}

.modify-placement{
    display: flex;
    justify-content: end;
}

.modify-style{
    padding-bottom: 5px;
    color: $green;
}

.modify-style-button {
    background-color: transparent !important;
}

.element-padding {
    padding-right: 30px !important;
}

.pricing-formatting {
    font-size: small;
    font-style: italic;
    text-align: right;
    padding-bottom: 10px;
}

.disclaimer-formatting {
    font-size: small !important;
    text-align: center;
    padding-right: 0px;
    margin-left: 5px;
}

.stacked-container {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Horizontally center items */
    padding-top: 5px;
    padding-bottom: 5px;
}

.dispatch-control-style{
    justify-content: end;
    display: grid;
}

/* Media query for screens larger than 768px */
@media screen and (min-width: 768px) {

    .date-override {
        padding-left: 40px;
    }

    .checkbox-override {
        margin-left: 0px !important;
    }

    .forced-button-width {
        width: 160px;
    }

    .width-adjustment {
        width: 93%
    }

    .time-padding {
        padding: 6px !important;
    }
}

@media screen and (max-width: 768px) {


    .service-fee-alignment-mobile {
        padding-right: 0px !important;
    }

    .checkbox-override {
        padding-left: 20px;
        padding-right: 0px;
    }

    .gatecode-override {
        padding-left: 0px;
        width: 100%;
    }

    .gatecode-override input{
        width: 100%;
    }

    .width-adjustment {
        width: 85%
    }
}

.remove-dispatch{
    display: flex;
    justify-content: end;
}

.dispatch-time-helper {
    margin-bottom: -15px;
    padding-left: 25px;
    padding-right: 25px;
}

.add-to-cart-padding{
    padding-bottom: 50px;
}