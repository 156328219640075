﻿@import 'values';

.text-bold {
    font-weight: bold !important;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-no-decoration {
    text-decoration: none !important;
}

.fp-green,
.fa-green {
    color: $green;
}

.fp-red,
.fa-red {
    color: $error-red;
}

.spacer-xs {
    margin-top: $spacing-sm/2;
}

.spacer-sm {
    margin-top: $spacing-sm;
}

.spacer-md {
    margin-top: $spacing-md;
}

.spacer-lg {
    margin-top: $spacing-lg;
}

.center-horizontally {
    display: flex;
    justify-content: center;
}

.center-vertically {
    display: flex;
    align-items: center;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}