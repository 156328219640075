@import '../../../content/styles/site/values/_colors.scss';
@import '../../../content/styles/site/values/_variables.scss';
@import '../../../content/styles/_site.scss';

.stop-move-modal {
    text-align: center;
    font-family: OpenSans-Regular;
    color: #323537;
    font-size: 14px;
    letter-spacing: -0.4px;
    line-height: 19px;

    .modal-body {
        padding-bottom: 0;
    }

    h3 {
        color: #323537;
        font-size: 16px;
        font-weight: bold;
        line-height: 22px;
    }

    .header-text {
        font-weight: bold;
    }

    button {
        color: $fp-green;
        font-size: 14px;
        font-weight: bold;
        width: 50%;
        height: 45px;
        text-transform: uppercase;
        text-align: center;
        border-top: 1px solid $fp-gray-dc;
        margin: 0 !important;
        background-color: white;
    }

    button:first-child {
        border-right: 1px solid $fp-gray-dc;
    }
}
