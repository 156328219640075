@import "../../../content/styles/site/values";

.fpDisabled {
    opacity: .3;
}

.fpEnabled {
    opacity: 1;
}

.payments {
    float: left;
    margin: 5px;
}
.rapidAlert{
    padding-top:15px;
}

.features {
    padding: 20px 0;
    font-size: 14px !important;

    .fa {
        color: $green;
        text-align: left;
    }
}

.bold-modal {
    font-weight: bold;
}

.three-column-payment {
    padding: 10px;

    .payment-card {
        width: 210px;
        padding-left: 0;
        margin-left: 0;
        padding-bottom: 130px;

        .payment-card-type img {
            width: 20px;
            height: auto;
        }
    }

    .edit {
        color: $green;
        font-size: .8em;
        text-transform: uppercase;
    }

    .inline {
        display: inline;
    }

    .title-edit-wrapper {
        padding-top: 10px;
    }
}

.payment-submit-group {
    margin-top: 30px;
    margin-bottom: 30px;
}

.addresses {
    h4 {
        font-style: italic;
        font-weight: bold;
    }
    .well {
        background-color: transparent;
    }
}

#cto_cookies_header_message {
    display: none;
}