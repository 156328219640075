﻿@import "values";

.header {
    padding: 48px 0;
    background: #eeeeee;
    border-radius: 0;
    text-align: center;
}

@media (max-width: $screen-sm) {
    #app-header-sub .header-tabs.horizontal li {
        text-align: center;

        a {
            padding: 0 5px 20px 5px;
        }
    }
}
