﻿@import 'values';

.section {
    margin-bottom: $spacing-sm/2;

    .section-header {
        display: flex;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 10px;
        color: $green;

        .section-title {
            flex-grow: 1;
            margin: 0;
            text-transform: uppercase;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .section-header-control {
            width: 20px;

            &:hover {
                color: lighten($green, 10%);
            }
        }

        button,
        a {
            &.section-header-control {
                background: none;
                border: 0;
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}

.async-section {
    position: relative;

    .async-section-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;

        .async-section-overlay-content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            font-size: 24px;
            color: $green;
        }
    }

    &.loading .async-section-content {
        filter: blur(1px) opacity(75%);
    }
}

.container .async-section .async-section-overlay {
    margin: 0 -15px;
}

@media (min-width: $screen-sm-min) {
    .section {
        margin-bottom: $spacing-md;
    }
}

.spinner {
    background: white;
}
