@import "../../../content/styles/site/values";

.fp-settings-billing {
    .features {
        border-bottom: 0;

        .fa {
            color: $green;
        }
    }

    .payment-margin-top {
        margin-top: 0.5em;
    }
}

label.addPaymentSameAddress {
    display: inline-flex;
}

.control-label-billing {
    text-align: left !important;
    font-weight: bold;
}

.button-control {
    width: 20px;
    color: $green;

    &:hover {
        color: lighten($green, 10%);
    }
}

button,
a {
    &.button-control {
        background: none;
        border: 0;
        padding-left: 0;
        padding-right: 0;
    }
}

.invisibleCheckbox {
    visibility: hidden;
}

.creditCardExpireWarning{
    .modal-header{
        padding: 25px 0;
        padding-bottom: 5px;
    }
}
