@import "../../../../content/styles/site/values/variables";

@media(max-width: $max-width-sm-phone) {
    .syncInfoModal {
        .modal-header {
            padding: 10px 0;

            button {
                margin-top: -30px;
                margin-right: 5px;
            }
        }
        .modal-body {
            padding-bottom: 10px;
        }
    }
}
