html,
body {
    // use !important since css scopes are leaking
    background-color: white !important;
}

.cancel-container {
    background-color: white;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.cancel-header {
    background-color: white;
    display: flex;
    height: 100px;
    align-items: center;
}

.cancel-spinner {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
}