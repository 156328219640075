.payment-history {
    .row-spacing {
        margin-bottom: 1em;
    }

    .grid {
        height: auto;

        button {
            width: auto;
        }
    }
}
