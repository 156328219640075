@import '../../content/styles/site/values/_colors.scss';
@import '../../content/styles/site/values/_variables.scss';
@import '../../content/styles/_site.scss';

.congrats-component {
    padding-top: 30px;

    .congratsText {
        display: inline;
        height: 18px;
        width: 866px;
        color: #323537;
        font-family: "OpenSans-Bold";
        font-size: 20px;
        font-weight: bold;
        letter-spacing: -0.57px;
    }

    .inline {
        display: inline;
    }

    .navigation {
        height: 25px;
        width: 95px;
        color: #5ABB62;
        font-family: "OpenSans-Bold";
        font-size: 20px;
        font-weight: bold;
        letter-spacing: -0.57px;
        line-height: 25px;
        text-align: right;
    }
}
