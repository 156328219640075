@import 'globals';
@import 'mixins';

/*
* THIS FILE IS USED IN MODERN .NET PAGES (imported via CustomerDotNet/modern/styles/modern.scss)
* Make sure to test changes against both environments
*/

@keyframes loader-progress-indeterminate {
    0% {
        right: 100%
    }

    30% {
        left: 0
    }

    80% {
        right: 0
    }

    100% {
        left: 100%;
        right: 0
    }
}

@mixin defaultIconColors () {
    // Default colors for some svg icons
    svg {
        &.svg-circle-check {
            fill: $green;
        }

        &.svg-issue {
            fill: $orange-yellow;
        }

        &.svg-help {
            fill: $dark-cyan-blue;
        }

        &.svg-warning {
            fill: $light-red;
        }
    }
}

.loading-indicator {
    background-color: rgba($white, 0.3);
    height: 4px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    // Force it on another layer
    transform: translateZ(0);
    z-index: 100;

    .progress {
        background-color: $cyan-blue;
        height: 100%;
        left: 0;
        opacity: 1;
        position: absolute;
        right: 0;
        transition: width $transition-medium;

        &.first,
        &.second {
            animation: loader-progress-indeterminate 3s infinite;
            right: 100%;
        }

        &.second {
            opacity: 0.3;
        }
    }
}

body {
    background: $light-gray;
}

#app-page {
    min-height: 550px + $main-header-height + $base-grid;
    padding-top: $main-header-height;           // Pad top of page so app-content is pushed below header.
    position: relative;
    transition: filter $transition-fast;

    // When tabs are visible...
    &.header-control {
        // add tab height to top padding.
        padding-top: $main-header-height + $main-header-tab-height;
    }

    &.inactive {
        filter: blur(10px);
    }
}

.app-header {
    background: $adc-route-default;
    color: $white;
    fill: $white;
    height: $main-header-height;
    left: 0;
    padding-left: $main-nav-width;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;
    transition: height $transition-slow,
                background-color $transition-medium;
    z-index: 3;

    #app-page.header-control & {
        height: $main-header-height + $main-header-tab-height;
        transition-delay: $transition-fast;
    }

    button {
        background: transparent;
        border: none;
        color: transparent;
        cursor: pointer;
        outline: none;
        padding: 0;
        position: absolute;
        z-index: 2;
    }

    #btn-menu {
        height: 30px;
        left: 24px;
        top: 16px;
        width: 30px;
    }

    .labels {
        height: $main-header-height;
        position: relative;
        width: 100%;

        $header-label-width: 400px;
        $back-btn-size: 20px;

        // .NET does not support hyphenated IDs on server controls so we use btnBack there
        #btn-back, #btnBack {
            height: $back-btn-size;
            left: $base-grid-2x;
            opacity: 0;
            top: ($main-header-height - $back-btn-size) / 2;
            transition: all $transition-medium;
            width: $back-btn-size;
        }

        label {
            @include text-no-wrap-ellipsis;

            font-size: 24px;
            font-weight: 300;
            line-height: $main-header-height;
            margin: 0;
            padding: 0;
            position: absolute;
            text-align: center;
            transform: translateZ(0);
            transition-duration: $transition-fast;
            transition-property: left, width, font-size;
            width: $header-label-width;
            z-index: 1;

            // Center main title.
            &.main-title {
                left: calc(50% - #{$header-label-width / 2});
            }

            // Push sub-route title off screen.
            &.sub-title {
                left: 100%;
            }
        }

        // When sub route is shown.
        &.is-sub-route {
            #btn-back, #btnBack {
                opacity: 1;
            }

            label {
                // Pull main title to left.
                &.main-title {
                    cursor: pointer;
                    font-size: 20px;
                    left: 36px;
                    text-align: left;
                    width: auto;
                }

                // Center sub-route title.
                &.sub-title {
                    left: calc(50% - (#{$header-label-width} / 2));
                }
            }
        }
    }
}

@media screen and (max-width: $break-large) {
    .app-header {
        padding-left: 0;

        .labels.is-sub-route {
            #btn-back, #btnBack {
                left: $base-grid-8x;
            }

            label {
                &.main-title {
                    // Allow space for menu and back button.
                    left: $base-grid-11x;
                }
            }
        }
    }
}

#app-content {
    display: flex;
    font-size: $font-size-normal;
    min-height: calc(100vh - #{$main-header-height + $main-footer-height});
    position: relative;

    margin-left: $main-nav-width;
    transition: margin-left $transition-fast;
    transform: translate3d(0,0,0);
    width: calc(100% - #{$main-nav-width});

    // Adjust for sub-tab header control.
    #app-page.header-control & {
        min-height: calc(100vh - #{$main-header-height + $main-header-tab-height + $main-footer-height});
    }
}

#app-footer {
    color: $black-40;
    cursor: default;
    line-height: $main-footer-height;
    margin-left: $main-nav-width;
    position: relative;
    text-align: center;
    transition: margin-left $transition-fast;
    width: calc(100% - #{$main-nav-width});

    div {
        font-size: $font-size-xs;
        line-height: 18px;
        text-align: center;

        img {
            height: 20px;
            vertical-align: middle;
        }
    }

    ul {
        font-size: $font-size-xs;
        position: absolute;
        right: $base-grid-2x;
        top: 0;

        li {
            & + li {
                margin-left: $base-grid-2x;
            }

            a:hover {
                text-decoration: underline;
            }
        }
    }
}

// Phone.
@media screen and (max-width: $break-small) {
    $phone-footer-height: $main-footer-height * 2;

    #app-content {
        min-height: calc(100vh - #{$main-header-height + $phone-footer-height});

        #app-page.header-control & {
            min-height: calc(100vh - #{$main-header-height + $main-header-tab-height + $phone-footer-height});
        }
    }

    #app-footer {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: $phone-footer-height;
        text-align: center;
        width: 100%;

        ul {
            position: static;
        }
    }
}

// Tablet.
@media screen and (max-width: $break-large) {
    body {
        #app-content {
            margin-left: 0;
            width: 100%;
        }

        #app-footer {
            margin-left: 0;
            text-align: left;
            width: 100%;

            & > div {
                margin-left: $base-grid-2x;
                text-align: left;
            }
        }
    }
}

@include defaultIconColors;

.popover-menu .items-list li a {
    @include defaultIconColors;
}

// Include navigation style
@import 'app-nav';

// Include app-profile style
@import 'app-profile';
