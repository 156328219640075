﻿@import 'values';

.icon-sm {
    font-size: $icon-sm;
}

.icon-lg {
    font-size: $icon-lg;
}

// We are not using the default bootstrap glyphicons. Replace them with the corresponding fontawesome icon.
.glyphicon {
    display: inline-block;
    @include fontawesome();
}

.glyphicon-chevron-right:before {
    content: "\f054";
}

.glyphicon-chevron-left:before {
    content: "\f053";
}

.glyphicon-chevron-up:before {
    content: "\f077";
}

.glyphicon-chevron-down:before {
    content: "\f078";
}

@media (max-width: $screen-sm - 1) {
    .icon-sm {
        font-size: 18px;
    }

    .icon-lg {
        font-size: 24px;
    }
}
