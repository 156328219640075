@import '../../content/styles/animations.scss';
@import '../../content/styles/site/values/variables.scss';

.setup-equipment-component {
    background: #f0f0f0;
    height: 100%;
    width: 100vw;
    
    .aw-progress-indicator {
        position: absolute;
        left:0;
        right:0;
        top: calc(50% - 100px);
        background-image: url('../../content/images/green.png');
        background-size: 180px 180px;
        display: inline-block;
        width: 180px;
        height: 180px;
        margin-left: auto;
        margin-right: auto;
    }

    .hide-spinner {
        position: relative;
        display: none;
    }

    iframe {
        height: 100%;
        width: 100%;
        margin-top: -8px;
        margin-bottom: -14px;
        border: none;
    }

    #app-header-sub, #btn-menu {
        display: none;
    }

    #app-page, #app-page.header-control {
        padding-top: 0 !important;
    }

    .banner {
        padding-top: 30% !important;
    }

    @media only screen and (min-width : 767px) {
        .aw-progress-indicator {
            position: absolute;
            left: 50%;
            top: 50%;
            margin-top: -98px;
            margin-left: -98px;
        }
    }
}

.view-container {
    margin-bottom: unset !important;
}

 iframe[name=google_conversion_frame] {
     display: none;
 }