@import 'globals';
@import 'mixins';

#app-nav {
    $nav-offset: 12px;

    background: $white;
    box-shadow: 0 1px 2px 0 rgba($black, 0.2),
                0 3px 6px 0 rgba($black, 0.05);
    height: calc(100% - #{$nav-offset});
    left: 0;
    position: fixed;
    top: $nav-offset;
    width: $main-nav-width;
    transform: translateX(0);
    transition: transform $transition-fast;
    z-index: 4;

    &.open {
        box-shadow: 0 1px 2px 0 rgba($black, 0.2),
                    0 3px 6px 0 rgba($black, 0.4);
        transform: translateX(0);
    }

    .nav-wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        min-height: 100%;
        overflow: auto;

        // Pushing this mixin after the properties because it only adds additional classes and does not
        // add any css properties to this element
        @include dealerLogoNav($base-grid);

        // System select and login name select styling
        .selected-system-description,
        .app-profile .login-name {
            @include text-no-wrap-ellipsis;

            $height: $base-grid-4x;

            border-radius: #{$height / 2};
            color: $black-80;
            cursor: default;
            font-size: $font-size-lg;
            height: $height;
            line-height: $height;
            margin: $base-grid-2x $base-grid;
            padding: 0 $base-grid-2x;
            text-align: center;

            &.enabled {
                background-color: $light-gray;
                cursor: pointer;

            }
        }

        // Main navigation
        nav {
            // Allow it to stretch as much as it needs
            flex: 1 0 auto;

            ul {
                /*border-top: 1px solid #f0f0f0;*/

                .home &,
                .settings & {
                    color: $adc-route-default;
                }

                .video & {
                    color: $adc-route-video;
                }

                .activity & {
                    color: $adc-route-activity;
                }

                .automation & {
                    color: $adc-route-automation;
                }

                .users & {
                    color: $adc-route-users;
                }

                li {
                    a {
                        @include text-no-wrap-ellipsis;
                        @include unselectable;

                        border-left: 0 solid $white;
                        /*border-bottom: 1px solid #f0f0f0;*/
                        color: $black-80;
                        display: block;
                        font-size: 15px;
                        height: 48px;
                        line-height: 55px;
                        padding-left: 16px;
                        padding-right: $base-grid;
                        text-transform: capitalize;
                        transition: all $transition-fast;

                        &.active {
                            border-left: 4px solid currentColor;
                            color: inherit;
                            cursor: default;
                        }

                        &:hover, &:focus {
                            text-decoration: none;
                        }
                    }

                    &.secondary-link a.active {
                        border-left: none;
                    }
                }
            }
        }
    }

    // Phone.
    @media screen and (max-width: $break-small) {
        position: fixed;
    }

    // Tablet.
    @media screen and (max-width: $break-large) {
        transform: translateX(-$main-nav-width);
    }
}

