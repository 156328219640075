@import '../../content/styles/site/values/_colors.scss';

.moving-progress-bar {
    padding: 20px 0 10px 0;
    margin-bottom: -40px;

    .dot-navigation {
        display: inline-flex;
        align-items: center;
        width: 100%;
        justify-content: center;

        ul {
            position: relative;
            display: inline-flex;
            list-style-type: none;
            justify-content: space-between;
            margin: 0;
            padding: 0;
            cursor: default;

            li {
                position: relative;
                padding: 0;
                line-height: 60px;
                display: table;
                justify-content: center;
                color: black;
                font-size: 10px;
                font-weight: bold;
                letter-spacing: -0.4px;
                text-align: center;
                color: #757577;
            }

            li a {
                top: 2px;
                left: 43%;
                width: 16px;
                height: 16px;
                outline: none;
                border-radius: 50%;
                border: 1px solid $fp-green;
                background-color: white;
                text-indent: -999em;
                cursor: default; /* make the text accessible to screen readers */
                position: absolute;
                z-index: 100;
            }

            .active {
                a {
                    background-color: $fp-green;
                }
            }

            li.active {
                color: $fp-green;
                text-decoration: underline;
            }

            .inactive {
                a {
                    background-color: $white;
                }
            }

            .nav-connector {
                position: absolute;
                top: 7px;
                left: 15%;
                height: 1px;
                width: 68%;
                border: 1px dashed $fp-gray-dc;
            }
        }
    }

    .dot-navigation.circles {
        display: inline-flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        position: relative;
        top: -80px;
        z-index: 0;
        left: -5px;
        background: none;

        ul li {
            a {
                height: 26px;
                width: 26px;
                border: none;
                background-color: transparent;
            }
        }

        .active a {
            background-color: white;
            border: 2px solid #D3B934;
        }

        .inactive a {
            background-color: transparent;
            border: none;
        }
    }
}

.ua-ios {
    /* CSS specific to iOS devices */
    .moving-progress-bar {
        .dot-navigation.circles .active a {
            position: absolute;
            top: 2px;
        }
    }

    .dot-navigation li a {
        top: 2px;
        left: 46%;
    }
}
