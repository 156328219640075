﻿@import 'values';

.form-group {
    border-bottom: 1px solid $gray10;
}

.form-control {
    border-radius: 0;
}

.form-control-static {
    overflow: hidden;
    text-overflow: ellipsis;
}

.control-label {
    text-align: left !important;
}

.has-error-black {
    color: $black;
    font-weight: bold;
    font-size: 1.1em;
    margin-bottom: 10px;
}
.has-error {
    color: $error-red;

    .control-label {
        color: $error-red;
    }

    .form-control,
    .form-control:focus {
        border-color: $error-red;
    }

    .input-group-btn .btn {
        color: $error-red;
        border-color: $error-red;
    }
}

.form-group.has-error {
    position: relative;

    .error-message {
        display: inline-block;
        position: absolute;
        bottom: 8px;
        color: $error-red;
        white-space: nowrap;
    }
}

.editing {
    .form-group {
        border-bottom: 0;
    }
}

.support-message {
    font-size: .9em;
    font-style: italic;
    text-align: left;
    color: $gray70;
}

@media (min-width: $screen-sm-min) {
    .form-control,
    .form-control-static {
        text-align: right;
    }
}