﻿@import 'values';

.adc-mobile-app {
    padding-bottom: 100px !important;
    padding-top: 44px !important;

    &.hide-header-control {
        padding-top: 0px !important;
    }

    .mobileFooter {
        background: $black !important;
        margin: unset !important;
        width: 100% !important;
        background-image: none !important;
        background-repeat: repeat !important;
        background-attachment: scroll !important;
        background-position: 0% 0% !important;
        position: fixed;
        bottom: 0pt !important;
        left: 0pt !important;

        .icon-container {
            border-right: 1px solid $white;

            &.selected {
                background: $green !important;
            }
        }

        i {
            color: $white;
        }

        a {
            color: $black;
            width: 100%;
        }

        .center {
            margin: auto;
            width: 50%;
            padding: 10px;
        }
    }

    .position-absolute {
        position: absolute !important;
    }

    .mobileFooter-content {
        height: 30px !important; // Replace with the height your footer should be
        width: 100% !important; // Visible width of footer
        margin: auto !important;

        .popover {
            left: 7px !important;
            min-width: 12em !important;

            .form-group {
                border: unset !important;
            }

            ul.mobile-submenu {
                min-width: 175px;

                li {
                    border-bottom: 1px solid $gray10;

                    &:first-child {
                        border-top: 1px solid $gray10;
                    }

                    &:hover {
                        background-color: $gray10;
                    }

                    > a {
                        padding: 1em;
                        display: inline-block;
                        height: 100%;
                        width: 100%;
                    }
                }
            }
        }

        .col-xs-4 {
            padding: unset !important;
        }

        button {
            background-color: Transparent;
            background-repeat: no-repeat;
            border: none;
            cursor: pointer;
            overflow: hidden;
            outline: none;
            display: block;
            width: 100%;
            height: 100%;
        }

        .popoverSupport {
            left: 120px !important;
            min-width: 12em !important;

            .form-group {
                border: unset !important;
            }
        }
    }

    #app-content {
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .app-header {
        background: $white !important;
        color: $black !important;
        height: unset !important;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 6px 0 rgba(0, 0, 0, 0.05);
        padding-left: 0;

        button {
            display: none;
        }

        #app-header-sub .header-tabs.horizontal li a {
            color: $black !important;
        }

        #app-header-sub .header-tabs.horizontal li a.active {
            box-shadow: inset 0 -4px 0 0 rgba(90, 187, 98, 1) !important;
        }

        .labels {
            display: none !important;
        }
    }


    .container {
        padding: 0 !important;
        border-radius: 5px;
    }

    .view-container {
        margin: 10px;
        background-color: #f0f0f0;
        box-shadow: none;
    }

    .section {
        .section-header {
            color: $white !important;
            background: $black !important;
            margin-top: unset !important;
            height: 4em !important;
            border-left: solid 0.5em $green;

            i {
                display: none;
            }

            i.fa-pencil, i.fa-times {
                display: inline;
                margin-right: 0.5em !important;
            }
        }

        .section-header-control {
            width: unset !important;
        }

        .section-title {
            color: $white !important;
            text-align: left;
            font-size: 18px !important;
            font-weight: 500 !important;
            padding-left: 15px !important;
        }

        .section-content {
            padding: 15px;
            background-color: white;
            border-radius: 3px;
            margin-bottom: -15px;
            padding-top: 10px;
            margin-top: -10px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 6px 0 rgba(0, 0, 0, 0.05);
        }
    }

    #app-footer {
        .container {
            padding: 0 20px !important;
        }
    }

    .row {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .fp-cart-item {
        .row {
            margin-left: -15px !important;
            margin-right: -15px !important;
        }
    } 

    .footer-content {
        .row {
            margin-left: -15px !important;
            margin-right: -15px !important;
        }
    }
    
    .offers {
        div.cart-info {
            width: calc(12em + 3vw) !important;
            margin: auto auto 50px !important;
        }
        .row {
            margin-left: unset !important;
            margin-right: unset !important;
        }
    }

    .col-xs-12 {
        &.remove-padding {
            padding-left: 0px !important;
            padding-right: 0px !important;
        }
    }
}
