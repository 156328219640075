﻿@import 'values';

h1 {
    font-size: $font-size-h1;
    color: $font-color-h1;
}

h2 {
    font-size: $font-size-h2;
    color: $font-color-h2;
}

h3 {
    font-size: $font-size-h3;
    color: $font-color-h3;
}

h4 {
    font-size: $font-size-h4;
    color: $font-color-h4;
}

h5 {
    font-size: $font-size-h5;
    color: $font-color-h5;
}

@media (max-width: $screen-sm - 1) {
    h1 {
        font-size: 24px;
    }

    h2 {
        font-size: 24px;
    }

    h3 {
        font-size: 18px;
    }

    h4 {
        font-size: 18px;
    }

    h5 {
        font-size: 14pt;
    }
}
