﻿@import 'values';

// NOTE: ADC footer styles use the ID #app-footer. To override these styles we need to include the same
// ID to ensure that our styles are more specific.
#app-footer.footer {
    // BEGIN: ADC Overrides
    height: auto;
    margin-bottom: -96px;

    div {
        font-size: 12px;
        text-align: left;
    }

    ul {
        position: static;

        li + li {
            margin-left: 0;
        }
    }

    img {
        height: auto;
    }
    // END: ADC Overrides

    $footer-background-color: #525357;
    $footer-divider-color: #323537;
    $footer-button-font-color: #323537;

    $footer-social-background-color: #323537;

    $footer-links-font-size: 14px;
    $footer-links-header-color: $brand-color-fontpoint;

    $footer-copyright-font-size: 11px;
    $footer-copyright-font-color: #ADAEAF;
    $footer-copyright-link-color: #EAEAEB;

    $footer-terms-font-size: 10px;
    $footer-terms-font-color: #323537;
    $footer-terms-link-color: #ADAEAF;

    display: block;
    background-color: $footer-background-color;
    @include clearfix();

    a {
        color: $gray10;
        text-decoration: none;
    }

    .btn-footer {
        padding: 20px;
        background: linear-gradient(180deg,#fff 0,#ddd);
        color: $footer-button-font-color;
        font-weight: bold;
        text-transform: uppercase;

        &:after {
            @include fontawesome();
            content: ' \F054';
        }

        &:hover {
            background: $white;
            color: $footer-button-font-color;
            text-decoration: none;
        }
    }

    .social {
        margin: 0;
        background-color: $footer-social-background-color;

        ul {
            margin-bottom: 0;
        }

        a {
            display: block;
            padding-top: 20px;
            padding-bottom: 20px;
            color: $white;
            font-size: 18px;
        }

        .fa-facebook:hover {
            color: $brand-color-facebook;
        }

        .fa-twitter:hover {
            color: $brand-color-twitter;
        }

        .fa-google-plus:hover {
            color: $brand-color-twitter;
        }

        .fa-youtube-play:hover {
            color: $brand-color-youtube;
        }
    }

    .footer-content {
        margin: 0;
        padding-top: $spacing-sm/2;

        .logo {
            margin-bottom: $spacing-sm/2;

            img {
                max-width: 125px;
            }
        }

        .links {
            h4 {
                text-transform: uppercase;
                color: $footer-links-header-color;
                font-weight: bold;
                font-size: $footer-links-font-size;
            }

            ul {
                margin-top: 0;
                list-style-type: none;
                padding: 0;

                li {
                    padding: 5px 0;
                    font-size: $footer-links-font-size;
                }
            }
        }

        .divider {
            margin-top: $spacing-sm;
            margin-bottom: $spacing-sm/2;
            border-bottom: 1px solid $footer-divider-color;
        }

        .partners {
            img {
                max-width: 125px;
                margin: 5px 0;
            }
        }

        .copyright {
            color: $footer-copyright-font-color;
            font-size: $footer-copyright-font-size;

            a {
                color: $footer-copyright-link-color;
                text-decoration: none;
            }

            .list-inline {
                margin-left: -5px;
            }

            ul li {
                padding-left: 2px;
                padding-right: 2px;
            }
        }

        .terms-conditions {
            margin-top: $spacing-sm/2;
            font-size: $footer-terms-font-size;
            color: $footer-terms-font-color;

            a {
                color: $footer-terms-link-color;
                text-decoration: none;
            }
        }
    }
}
