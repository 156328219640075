@import '../../content/styles/site/values/_colors.scss';
@import '../../content/styles/site/values/_variables.scss';
@import '../../content/styles/_site.scss';

.moving-component {
    .text-info {
        color: inherit;
    }

    font-family: OpenSans-Regular;
    margin: 0 auto;
    padding-bottom: 80px;
    letter-spacing: -0.51px;

    h3 {
        font-family: OpenSans-Bold;
        color: $black;
        line-height: 25px;
        font-size: 20px;
    }

    section {
        margin: 20px 0;
        padding: 0px;

        p {
            font-size: 16px;
            letter-spacing: -0.59px;
            line-height: 27px;
        }
    }

    a {
        text-decoration: underline;
    }

    p {
        line-height: 22px;
        margin: 0;
    }

    .divider {
        width: inherit;
        margin-bottom: 3px;
        border: 1px solid #DCDCDC;
        box-sizing: border-box;
    }

    .subheader {
        color: #323537;
        font-size: 14px;
        letter-spacing: -0.4px;
        line-height: 20px;
        font-family: OpenSans-Bold;
        display: block;
        padding-bottom: 5px;
    }

    .moving-form-link, .status-of-move, .steps {
        border: 1px solid $fp-gray-dc;
        margin: 15px 0;
        padding-top: 10px;
        padding-bottom: 10px;
        //box-shadow: 0 0 4px 0 rgba(0,0,0,0.5);
        > *:not(.subheader) {
            display: inline-block;
        }

        .table-cell {
            display: table-cell;
        }

        .moving-truck {
            display: inline-block;
            text-align: center;
            padding: 0 20px;
            position: relative;
            top: -10px;
        }

        i {
            float: right;
        }
    }

    .steps {
        border-top: none;
        border-right: none;
        border-bottom: 1px solid $fp-green;
        border-left: 3px solid $fp-green;
        padding: 10px 15px;
    }

    .steps-container {
        padding: 0;

        .subheader {
            display: inline-block;
        }
    }

    .steps-border {
        border-bottom: none;
    }

    .steps-instructions {
        margin-bottom: 20px;
        padding-bottom: 15px;

        span {
            border: 1.5px solid #CEB78D;
            border-radius: 2px;
            width: 30px;
            height: 33px;
            display: inline-block;
            padding-top: 5px;
            padding-left: 9px;
            margin-right: 15px;
        }

        p {
            padding: 10px 0;
        }

        border-bottom: 1px solid $fp-green;
    }

    .status-of-move {
        > div {
            padding: 15px 0;
        }

        .address {
            display: inline-block;
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }

    .status-of-move > div:first-child {
        border-bottom: 1px solid $fp-gray-dc;
    }

    .moving-notification-container {
        margin: 0;
        border: 2px solid $info-blue;

        .moving-status-container {
            padding: 15px;

            > * {
                display: inline-block;
            }

            .moving-status {
                text-transform: uppercase;
                font-family: OpenSans-Bold;
            }
        }

        > div {
            display: table-cell;
        }

        .status-block {
            width: 35px;
            background-color: $info-blue;
            border: 2px solid $info-blue;
            color: white;
            padding: 15px 10px;
            text-align: center;
            vertical-align: middle;

            .icon-container {
                margin: 0;
                top: 50%;
                left: 0%;

                i {
                    float: none;
                }

                span {
                    font-family: OpenSans-Bold;
                }
            }
        }
    }

    .noStatus {
        &.moving-notification-container {
            border: none;
            padding-left: 0;

            .status-block {
                display: none;
            }

            .moving-status-container {
                padding: 0;
            }

            .moving-status {
                display: none;
            }
        }
    }


    .pendingStatus {
        &.moving-notification-container {
            border: 2px solid $warning-yellow;

            .status-block {
                background-color: $warning-yellow;
                border: 2px solid $warning-yellow;
            }
        }
    }

    .sentStatus {
        &.moving-notification-container {
            border: 2px solid $fp-green;

            .status-block {
                background-color: $fp-green;
                border: 2px solid $fp-green;
            }
        }
    }

    .infoStatus {
        &.moving-notification.container {
            border: 2px solid #4B81AE !important;
            color: red;

            .status-block {
                background-color: #4B81AE;
                border: 2px solid #4B81AE;
            }
        }
    }

    .link-nav {
        padding-left: 0;
        padding-right: 0;

        ul {
            padding-left: 0;
            list-style-type: none;

            li {
                padding: 6px 0;
                letter-spacing: -0.4px;
                line-height: 19px;
            }

            a {
                text-decoration: underline;
            }

            i, img {
                padding-right: 10px;
                width: 35px;
                text-align: center;
            }
        }
    }

    .moving-confirmation {
        .heading {
            color: #757577;
            font-size: 15px;
            font-weight: bold;
            letter-spacing: -0.43px;
            line-height: 23px;
            font-family: OpenSans-Bold;
            padding-bottom: 10px;
            text-transform: uppercase;
        }

        .subheader {
            padding-bottom: 0;
        }

        .confirmation-container {
            padding: 5px;
            padding-bottom: 20px;
        }


        .left-side {
            padding-left: 0;
        }

        .right-side {
            padding-right: 0;
        }

        .residential-address, .movers-kit-address {
            background-color: #F2F2F2;
            padding: 20px;
        }
    }

    .moving-delivery {
        section {
            margin-bottom: 20px;
        }
    }
    ////// Errors //////
    ng-form {
        .alert {
            color: #CD2026;
            font-size: 10px;
            margin: 0;
            letter-spacing: -0.29px;
            line-height: 10px;
            padding-top: 5px;
            padding-bottom: 0;
            padding-left: 0;

            ul {
                list-style-type: none;
                padding: 0;
            }
        }

        fp-alert {
            div {
                line-height: unset !important;
                margin-bottom: 15px !important;
                padding: 15px;
                font-size: 14px !important;
            }

            button {
                padding-right: 15px;
            }
        }


        .has-error input, .has-error select {
            border: 1px solid #CD2026;
            background-color: rgba(205,32,38,0.07);
            box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.5), 0 0 5px 0 rgba(205,32,38,0.5);
        }

        .has-error .input-group-btn button {
            border: 1px solid #CD2026;
            background-color: rgba(205,32,38,0.07);
            box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.5), 0 0 5px 0 rgba(205,32,38,0.5);
        }


        label &.control-label {
            color: #666666;
        }
    }

    .required {
        color: #CD2026;
        font-size: 1.1em !important;
        font-family: OpenSans-Bold;
        opacity: 1 !important;
        letter-spacing: initial;
        position: relative;
    }
    ////// Form //////
    ng-form {
        .uib-datepicker-container {
            display: inline-block;
            padding: 5px;
            background-color: #f5f5f5;
            border: 1px solid #e3e3e3;
        }

        fieldset {
            padding: 15px 0;
        }

        .form-group {
            border-bottom: none;
        }

        .checkbox-styles {
            label {
                padding: 0;
                margin: 0;
                display: inline-block;
                color: $black;
                font-size: 14px;
                font-weight: 600;
                line-height: 19px;
                opacity: 1;
                text-transform: none;
            }
        }

        label {
            text-transform: uppercase;
            font-family: OpenSans-Light;
            opacity: 0.7;
            color: #666666;
            font-size: 12px;
            letter-spacing: -0.34px;
            margin-top: 10px;
        }

        input, select, .input-group-btn {
            text-align: left;
            height: 36px;
            border: 1px solid #979797;
            border-radius: 3px;
        }

        .input-group.datepicker {
            border: 1px solid #979797;
            border-radius: 3px;

            input, .input-group-btn, button {
                border: none;
            }
        }

        input[type='checkbox'] {
            margin-right: 5px;
            margin-bottom: 5px;
            vertical-align: middle;
        }
    }

    .form-header {
        display: table;
        padding: 20px 0 0 0;
        width: 100%;

        h3 {
            display: table-cell;
            width: 275px;
        }

        .line-break {
            width: inherit;
            margin-bottom: 3px;
            border: 1px solid $fp-gray-dc;
        }
    }
    ///// Buttons /////
    .button-toolbar {
        float: right;
        margin: 5px;

        button {
            height: 43px;
            width: 151px;
            border: 1.5px solid $green;
            border-radius: 4px;
            background-color: $white;
            margin: 0 5px;
        }

        .green-button {
            color: white;
            background-color: $fp-green;
        }

        .default-button {
            color: $fp-green;
            background-color: $white;
        }
    }

    .finish-button {
        float: none;
        text-align: center;

        button {
            float: none !important;
            border-radius: 31.5px;
        }
    }

    @media(min-width: $max-width-phone) {
        .col-sm-4 {
            padding-right: 0;
        }
    }

    @media(max-width:$max-width-phone) {
        section {
            margin-top: 0;

            p {
                font-size: 14px;
                line-height: unset;
            }
        }

        .moving-info {
            p {
                line-height: 22px;
                letter-spacing: -0.51px;
            }

            .steps-instructions {
                padding-left: 10px;
                padding-right: 10px;
            }

            .button-toolbar {
                float: none;
                margin: 20px 0 10px 0;
                width: 100%;
                display: inline-flex;

                button {
                    width: 100%;
                }
            }

            .status-of-move {
                display: block;

                .moving-truck {
                    padding: 15px 0px;
                    text-align: left;
                    position: unset;
                    top: 0;

                    img {
                        display: inline-block;
                    }
                }
            }
        }

        ng-form {
            input {
                margin-bottom: 0;
            }

            .button-toolbar {
                float: none;
                margin-top: 0;

                button {
                    width: 48%;
                    margin: 0 1%;
                }
            }
        }

        .moving-confirmation {
            .left-side, .right-side {
                padding-left: 0;
                padding-right: 0;
                padding: 0;
            }

            .confirmation-container {
                padding-bottom: 10px;
                line-height: 19px;

                .heading {
                    color: #999999 !important;
                }

                p {
                    display: inline;
                }

                .address {
                    padding-bottom: 15px;
                }
            }
        }
    }
}
