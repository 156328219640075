@import '../../content/styles/site/values';
@import "../../content/styles/adc/colors";

// angular-loading-bar
#loading-bar .bar {
    background: $loading-bar-color;
    height: 4px;
}

#loading-bar-spinner .spinner-icon {
    border-top-color: $loading-bar-color;
    border-left-color: $loading-bar-color;
}

#loading-bar .peg {
    -moz-box-shadow: $loading-bar-color 1px 0 6px 1px;
    -ms-box-shadow: $loading-bar-color 1px 0 6px 1px;
    -webkit-box-shadow: $loading-bar-color 1px 0 6px 1px;
    box-shadow: $loading-bar-color 1px 0 6px 1px;
}

.loading-spinner {
    -webkit-animation: spin 1s linear infinite;
    -moz-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
}

#app-header-sub .header-tabs.horizontal li a {
    display: block;
}

#app-nav {
    .nav-wrapper {
        height: calc(100% - 26px);
        display: block;

        nav {
            font-size: 0;
        }

        .fill {
            margin: 0;
            min-height: calc(100% - 26px);
            margin-bottom: -130px;
        }

        .fill:after {
            content: '';
            height: 126px;
            display: block;
        }

        .avatar {
            padding-bottom: 5px;

            .avatar-wrap {
                width: 60px;
                margin: 0 auto;

                div {
                    width: 60px;
                    height: 60px;
                }

                div:last-child {
                    display: none;
                }
            }

            .avatar-wrap:hover div:first-child {
                display: none;
            }

            .avatar-wrap:hover div:last-child {
                display: inline-block;
            }

            svg #circle1 {
                fill: #3686DB;
            }

            .initials {
                margin-top: -48px;
                font-size: 1.5em;
            }
        }

        .app-profile {
            margin: 0;
            height: 0px;
            text-align: center;
            font-size: 10pt;

            .user-name {
                max-width: 85%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                margin: 0px auto 19px auto;
            }

            button.login-name.btn {
                background-color: $adc-button-default;
                margin: 0 auto;
                text-transform: uppercase;
                width: 85%;
                font-size: 1em;
                border: none;
                border-radius: 4px;
            }
        }
    }

    .icon {
        width: 24px;
        height: 24px;
        margin-right: 16px;
    }
}

.transclude {
    height: 100%;
}

button.login-name.btn:hover {
    background-color: $grayHover !important;
}

.modal-content .btn {
    margin: 2px;
}

.setup-screen {
    #app-content {
        .setup-equipment-component {
            width: inherit;
        }
    }
}
