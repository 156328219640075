@import '../../content/styles/_site.scss';
@import '../../content/styles/site/values/_colors.scss';

.fp-order {
    margin-bottom: 10rem !important;
    padding: 3rem 1rem;
    border: 2px lightgray solid;
    border-radius: 2px;

    .fp-order-content {
        margin-bottom: 3rem;
    }

    .fp-order-actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
}
