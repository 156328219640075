﻿@import 'site/icons';
@import 'site/type';
@import 'site/tables';
@import 'site/forms';
@import 'site/buttons';
@import 'site/alerts';

@import 'site/banners';
@import 'site/headers';
@import 'site/modals';
@import 'site/sections';

@import 'site/_footer';

@import 'site/utilities';
@import 'site/adcMobile';

// Overrides
@font-face {
    font-family: MarkFont;
    src: url(../fonts/MarkFont-Light.woff2) format("woff2"),url(../fonts/MarkFont-Light.woff) format("woff");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: MarkFont;
    src: url(../fonts/MarkFont-Regular.woff2) format("woff2"),url(../fonts/MarkFont-Regular.woff) format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: MarkFont;
    src: url(../fonts/MarkFont-Bold.woff2) format("woff2"),url(../fonts/MarkFont-Bold.woff) format("woff");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: MarkFont;
    src: url(../fonts/MarkFont-Heavy.woff2) format("woff2"),url(../fonts/MarkFont-Heavy.woff) format("woff");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans-Light';
    src: url('../fonts/OpenSans-Light.ttf') format('truetype'), 
         url(../fonts/OpenSans-Light.woff) format('woff'), 
         url(../fonts/OpenSans-Light.woff2) format('woff2');
}

@font-face {
    font-family: 'OpenSans-Regular';
    src: url('../fonts/OpenSans-Regular.ttf') format('truetype'),
         url(../fonts/OpenSans-Regular.woff) format('woff'), 
         url(../fonts/OpenSans-Regular.woff2) format('woff2');
}

@font-face {
    font-family: 'OpenSans-Bold';
    src: url('../fonts/OpenSans-Bold.ttf') format('truetype'),
         url(../fonts/OpenSans-Bold.woff) format('woff'), 
         url(../fonts/OpenSans-Bold.woff2) format('woff2');
}

@font-face {
    font-family: 'CerebriSans-Bold';
    src: url('../fonts/OpenSans-Bold.ttf') format('truetype'),
         url(../fonts/OpenSans-Bold.woff) format('woff'), 
         url(../fonts/OpenSans-Bold.woff2) format('woff2');
}

@font-face {
    font-family: 'CerebriSans-Bold';
    src: url('../fonts/CerebriSans-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'CerebriSans-BoldItalic';
    src: url('../fonts/CerebriSans-BoldItalic.otf') format('opentype');
}

@font-face {
    font-family: 'CerebriSans-Book';
    src: url('../fonts/CerebriSans-Book.otf') format('opentype');
}

@font-face {
    font-family: 'CerebriSans-BookItalic';
    src: url('../fonts/CerebriSans-BookItalic.otf') format('opentype');
}

@font-face {
    font-family: 'CerebriSans-ExtraBold';
    src: url('../fonts/CerebriSans-ExtraBold.otf') format('opentype');
}

@font-face {
    font-family: 'CerebriSans-ExtraBoldItalic';
    src: url('../fonts/CerebriSans-ExtraBoldItalic.otf') format('opentype');
}

@font-face {
    font-family: 'CerebriSans-Heavy';
    src: url('../fonts/CerebriSans-Heavy.otf') format('opentype');
}

@font-face {
    font-family: 'CerebriSans-HeavyItalic';
    src: url('../fonts/CerebriSans-HeavyItalic.otf') format('opentype');
}

@font-face {
    font-family: 'CerebriSans-Italic';
    src: url('../fonts/CerebriSans-Italic.otf') format('opentype');
}

@font-face {
    font-family: 'CerebriSans-Light';
    src: url('../fonts/CerebriSans-Light.otf') format('opentype');
}
@font-face {
    font-family: 'CerebriSans-LightItalic';
    src: url('../fonts/CerebriSans-LightItalic.otf') format('opentype');
}
@font-face {
    font-family: 'CerebriSans-Medium';
    src: url('../fonts/CerebriSans-Medium.otf') format('opentype');
}
@font-face {
    font-family: 'CerebriSans-MediumItalic';
    src: url('../fonts/CerebriSans-MediumItalic.otf') format('opentype');
}
@font-face {
    font-family: 'CerebriSans-Regular';
    src: url('../fonts/CerebriSans-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'CerebriSans-SemiBold';
    src: url('../fonts/CerebriSans-SemiBold.otf') format('opentype');
}
@font-face {
    font-family: 'CerebriSans-SemiBoldItalic';
    src: url('../fonts/CerebriSans-SemiBoldItalic.otf') format('opentype');
}

body {
    background: #f0f0f0;
    font-size: 16pt;
    font-family: MarkFont, sans-serif;
}
