.linkExpired {
    margin-top: 10%;
}

.linkExpired > div > div {
    padding-top: 150px;
}

.linkExpired > div > div:last-of-type {
    height: 550px;
    border-left: 1px solid rgb(220, 220, 220);
    padding-left: 60px;
}

.container {
    background-color: none !important;
}