@import '../../../content/styles/site/_values.scss';

.credit-card-list {
    margin-left: inherit;
    font-size: 14px;

    .fpDisabled {
        opacity: .3;
    }

    .fpEnabled {
        opacity: 1;
    }

    .creditCardForm {
        padding-left: inherit;
    }

    .creditCardForm .row {
        margin-bottom: 1%;
    }

    .payment-card {
        width: 75%;
    }

    .btn-group.dropdown {
        width: 100%;

        button {
            width: 100%;
            text-align: left;
            color: #333 !important;
            background-color: white !important;
            padding: 0px;
        }

        button.dropdown-toggle {
            box-shadow: none;
            border: 2px solid #fff !important;
            border-bottom-color: #cfcfcf !important;
            border-bottom-width: 1px !important;
        }

        ul.dropdown-menu {
            width: 100%;

            li:hover {
                background-color: #cfcfcf !important;
            }
        }
    }

    .credit-icons {
        margin: 1em 0 1em 0;
    }

    .btnSubmit {
        width: 100%;
    }

    input[type="text"] {
        border: 1px solid #ccc !important;
    }

    input[type="number"] {
        border: 1px solid #ccc;
        width: 100%;
        max-width: 100%;
        display: block;
        font-size: 14px;
        -moz-appearance: textfield;
        padding: 8px 10px;
    }

    select {
        width: 100% !important;
        display: block;
        padding: 8px 10px;
        font-size: 14px;
    }

    .has-error, .fa {
        font-size: 14px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    select + i.fa {
        float: right;
        margin-top: -25px;
        margin-right: 5px;
        /*this is so when you click on the chevron, your click actually goes on the dropdown menu*/
        pointer-events: none;
        /*everything after this is just to cover up the original arrow 
        (for browsers that don't support the syntax used above) */
        background-color: #fff;
        padding-right: 5px;
    }

    .select-color {
        color: #8E9091;
        border: 1px solid #cfcfcf;
    }

    .break {
        margin-top: 0.2em;
    }
    /*Custom radio button starts*/
    input[type="radio"] {
        display: none;
    }

    input[type="radio"] + label:before {
        content: "";
        /* create custom radiobutton appearance */
        display: inline-block;
        width: 15px;
        height: 15px;
        padding: 2px;
        /* background-color only for content */
        background-clip: content-box;
        border: 1px solid #bbbbbb;
        background-color: #e7e6e7;
        border-radius: 50%;
    }
    /* appearance of checked radiobutton */
    input[type="radio"]:checked + label:before {
        background-color: #5ABB62;
    }
    /*Custom radio button ends*/
    input[type='radio'] + label {
        font-weight: normal;
    }
}

@media (max-width: $max-width-phone) {
    .credit-card-list {
        margin-bottom: 0px !important;

        .payment-card {
            max-width: 70% !important;
            padding-top: 0px;
            padding-bottom: 150px;
        }

        .credit-icons {
            margin: 0;
        }

        .credit-card-logo {
            float: left;
            max-width: 55px !important;
            padding-left: 10px;
        }
    }
}
