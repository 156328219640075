@import '../../../content/styles/site/values/_colors.scss';
@import '../../../content/styles/site/_values.scss';


.stacked-container {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Horizontally center items */
}

.fp-green{
    color: $green
}

/* Media query for screens larger than 768px */
@media screen and (min-width: 768px) {
    .notification-email-scale {
        font-size: 3em;
    }

    .notification-sms-scale {
        font-size: 3em;
    }

    .notification-check-scale {
        font-size: 2em;
    }

    .section-header-override {
        padding-bottom: 15px
    }
}

@media screen and (max-width: 768px) {
    .notification-email-scale {
        font-size: 2em;
    }

    .notification-sms-scale {
        font-size: 2em;
    }

    .notification-check-scale {
        font-size: 1em;
    }
}
