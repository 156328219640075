@import "../../../../content/styles/site/values/colors";
@import "../../../../content/styles/site/values/variables";

.ecommInfoModal {

    .modal-header {
        border: 0;
        background-color: $white;
        padding: 20px 0 10px 0;
    }

    .modal-body {
        padding: 20px 0 50px 0;
    }

    h3 {
        font-size: 2em;
        font-weight: bold;
        text-align: center;
    }

    h4 {
        font-size: 15pt;
        text-align: center;
        font-weight: bold;
    }

    p {
        font-size: 1.3em;
        text-align: justify;
    }

    .image-container {
        text-align: center;

        img {
            width: 90%;
        }
    }

    &.modal-content {
        .modal-header {
            button {
                i {
                    color: $gray70;
                    margin-top: 10px;
                }
            }
        }
    }

    .btn-group {
        padding-top: 50px;
        text-align: center;
        display: block !important;

        .inline-block {
            display: inline-block !important;
        }

        button {
            width: 10em !important;
            height: 50px;
            font-size: 1.2em;
            border: 3px solid $green;
            margin: 10px 5px;
            display: inline-block;
        }

        button:hover {
            background-color: $green;
            color: $white;
        }
    }

    @media screen and (max-width: $screen-md) {
        .btn-group {
            &.mobile-right {
                float: right;
            }

            .col-sm-6 {
                display: inline-block !important;
            }

            button {
                width: 9.5em;
                height: 50px;
                font-size: 1em;
            }
        }
    }

    @media screen and (max-width: $screen-sm) {
        .ecommInfoModal {
            text-align: center;
        }

        h3 {
            font-size: 1.6em !important;
        }
    }

    @media (max-width: 384px) {
        .btn-group {
            button {
                font-size: .9em;
                margin: 10px 0;
                padding: 4px !important;
                width: 9.3em !important;
            }
        }
    }
}
