@import '../../../../content/styles/site/values';

.password-requirements-component {
    li.requirement {
        &.complete:before,
        &.in-progress:before {
            display: inline-block;
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        &.complete:before {
            color: $green;
            content: "\f00c";
        }

        &.in-progress:before {
            color: $error-red;
            content: "\f00d";
        }
    }
}
