﻿@import 'values';

button,
.btn {
    &:active,
    &:focus {
        outline: 0 !important;
    }
}

.btn {
    border-radius: 0;

    &:disabled,
    &.disabled {
        color: $gray25;
    }
}

.btn-black,
.btn-green {
    border-width: 2px;
}

.btn-green {
    border-color: $green;
    background-color: transparent;
    color: $green;
}

.btn-black {
    border-color: #525356;
    background-color: transparent;
    color: #525356;
}

.btn-black,
.btn-green {
    &:disabled,
    &.disabled {
        border-color: $gray25;
    }
}

a {
    cursor: pointer;
}

.btn-testmode-selected{
    background:$green;
}