@import '../../../content/styles/site/_values.scss';

.error-header {
    width: 517px;
    height: 155px;
    background: url('../../../content/images/logo_color.svg') center;
    background-size: cover;
    margin-right: auto;
    margin-left: auto;
    margin-top: 5%;

    .error-text-container {
        margin: 0;
        width: 100%;
        position: absolute;
        padding-top: 200px;
    }

    .error-text {
        color: black;
        font-size: 28px;
        letter-spacing: -0.38px;
        line-height: 38px;
        margin-left: auto;
        margin-right: auto;
    }
}

@media(max-width: $max-width-phone) {
    .error-header {
        background: url('../../../content/images/logo_color.svg') center;
        background-size: contain;
        width: 90%;
        height: 100%;
        background-repeat: no-repeat;
        margin-top: 0;
        .error-text-container{
            position: unset;
        }
        .error-text {
            font-size: 20px;
            line-height: 32px;
            text-align: center;
        }
    }
}
