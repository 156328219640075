.confirmation-intro {
    padding-top: 2%;
    padding-right: 4%;
    padding-bottom: 2%;
    padding-left: 4%;
}

.confirmation-intro h2 {
    text-align: center;
    margin-bottom: 5%;
}

.payment-summary-content {
    background-color: #f7f7f7;
    padding: 3% 4%;
}

.payment-summary-content .amount {
    color: #5ABB62;
}

hr {
    border: 1px solid #3e3e3e;
}

.payment-confirmation-content {
    padding: 2% 4%;
}

.payment-confirmation-content .form-control, .sign-cancellation-rftp input {
    text-align: left;
}

.payment-card {
    margin-left: inherit;
    margin-right: inherit;
}

label {
    font-weight: normal;
}

.agree-cancellation{
    padding-left: 0 !important;
}
.input-group.datepicker {
    border: 1px solid #979797;
    border-radius: 3px;
}

.input-group.datepicker input, button {
    border: none;
}

.checkmark {
    width: 7%;
    float: left;
    margin-left: -30px;
}

.payment-confirmation-content .alert-danger {
    font-size: medium;
}

.payment-confirmation-content .alert-message {
    padding-left: inherit;
}

.process-cancellation {
    text-align: center;
    margin: 2%;
}

.sign-cancellation-rftp {
    padding: 0% 4%;
}

.sign-cancellation-rftp .checkmark {
    width: 3%;
    float: left;
    margin-left: -3%;
}

.sign-cancellation-rftp .sign-cancellation-rftp-header {
    margin-top: 2%;
}

.sign-cancellation p, .confirmation-offer-content p {
    margin: 0px;
}

.sign-cancellation .row, .sign-cancellation-rftp-content div {
    margin-bottom: 3%;
}

.complete-cancellation {
    color: #5ABB62;
    text-decoration: underline;
}

.complete-cancellation-disabled,
.complete-cancellation-disabled:hover,
.complete-cancellation-disabled:focus {
    color: #d7d7d7;
    cursor: not-allowed;
}

.confirmation-offer-content {
    text-align: center;
    background-color: #f7f7f7;
    padding: 2%;
    margin: 5%;
}

/*custom checkbox starts*/

.checkbox .cr {
    position: relative;
    display: inline-block;
    border: 1px solid #a9a9a9;
    width: 1.2em;
    height: 1.2em;
    float: left;
    margin-right: .5em;
}

.checkbox .cr .cr-icon {
    position: absolute;
    font-size: .9em;
    line-height: 0;
    top: 55%;
    left: 10%;
}

.checkbox label input[type="checkbox"] {
    display: none;
}

.checkbox label input[type="checkbox"] + .cr > .cr-icon {
    opacity: 0;
}

.checkbox label input[type="checkbox"]:checked + .cr > .cr-icon {
    opacity: 1;
}

.checkbox label input[type="checkbox"]:disabled + .cr {
    opacity: .5;
    cursor: not-allowed;
    background: #d7d7d7;
}
/*custom checkbox ends*/

@media (max-width: 992px) {
    .desktop-view {
        display: none;
    }

    .credit-card-list .creditCardForm{
        width: 100%;
    }

    .credit-card-list {
        font-size:inherit;
    }

    .phone-view {
        padding: 5%;
        text-align: center;
        margin-top: 5%;
        background-color: #f7f7f7;
    }

    .process-cancellation-phone .primary-cancellation-button{
        margin: 5% 0%;
    }

    .process-cancellation-phone p {
        margin: 0px;
    }
}

@media (min-width: 992px) {
    .phone-view, .phone-view-card, .phone-view-hr {
        display: none;
    }

    .credit-card-list .creditCardForm {
        width: 80%;
    }
}

//just for phones
@media (max-width: 767px) {
    .confirmation {
        font-size: 14px;
    }
}

@media (max-width: 477px) {
    .sign-cancellation-rftp .checkmark {
        width: 4%;
        float: left;
        margin-left: -4%;
    }
}