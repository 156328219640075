@import '../../content/styles/_site.scss';
@import '../../content/styles/site/values/_colors.scss';
@import '../../content/styles/site/values';

.fp-confirmation-modal-window-top {

    .modal-dialog {
        margin-top: 20vh;
    }

    .fp-confirmation-modal {
        padding: 0 !important;

        .spacer {
            flex-grow: 1;
        }

        .modal-body {
            display: flex;
            flex-direction: column;
            padding: 0 !important;

            .container {
                padding: 3rem !important;
                width: 100% !important;

                .modal-body-content {
                    margin: 3rem 0;
                    text-align: center;
                    font-size: initial;
                }

                .modal-actions {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    button {
                        margin: 2px 1rem;

                        @media (max-width: $screen-md-min) {
                            margin: 2px !important;
                        }
                    }
                }
            }
        }
    }
}
