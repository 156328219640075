.disabled {
    color: #ccc;
    cursor: not-allowed; 
}

.additional-condition-control {
    text-decoration: underline;
    font-style: italic;
    font-size: smaller;
    display: grid;
    justify-content: right;
    margin-top: 6px;
    cursor: pointer;
}

.condition-dropdown:not(:first-child){
    margin-top: 6px;
}

.terms{
    text-decoration: underline
}

select option:disabled{
    background-color: lightgray;
}

.condition-wrapper {
    position: relative;
}

.condition-wrapper i {
    position: absolute;
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
}


.additional-contact-wrapper {
    
}

.additional-contact-wrapper i {
    position: absolute;
    right: -20px;
    top: 25%;
    cursor: pointer;
}

.remove {
    font-size: 17px !important;
    cursor: pointer;
}

.remove-contact {
    font-size: 20px !important;
}

.add-more{
    margin-right: 5px;
}

.info-line {
    display: flex;
    justify-content: space-between;
}

.left-item {
    margin: 0;
}

.right-item {
    margin: 0;
    text-align: right;
}

.contact-view{
    padding-left: 0px;
    padding-right: 0px;
}

.section-title-svg{
    margin-bottom: 5px;
}

.first-name{
    padding-left: 5px;
    padding-right:5px;
}

.last-name{
    padding-right: 0px;
    padding-left: 5px;
}

.record-controls{
    padding-top: 15px;
}

.delete-record-control{
    padding-left: 0px;
}

.add-more-control{
    padding-right: 0px;
}