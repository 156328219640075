.helper-header{
    font-weight: bold;
    padding-left: 25px;
}

.modal-header-override{
    padding-bottom: 10px !important;
}

.close-icon{
    vertical-align: 0px;
    padding-top: 15px;
}