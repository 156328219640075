﻿@import "values";

.banner {
    padding: 16px 0px;
    background: #eeeeee;
    border-radius: 0;
    font-size: 16px;
    text-align: center;
    vertical-align: middle;

    &.banner-green {
        background-color: $green;
        color: $white;
        text-transform: uppercase;

        .banner-content {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: calc(0.65em + 0.62vmin);

            a {
                color: $white;
            }
        }
    }
}
