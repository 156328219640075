@import '../../../content/styles/site/values';

fp-preferences-notifications {
    .phone-selector {
        margin-bottom: $spacing-sm/2;
    }

    .btn-notification-phone {
        width: 50%;
        color: $white;
        background-color: $green;

        &:disabled,
        &.disabled {
            pointer-events: auto;
            cursor: pointer;
            color: $white;
            background-color: $gray25;
        }

        &:hover {
            color: $white;
            background-color: lighten($green, 10%);
        }
    }
    // This container is required to absolutely position the checkmark next to the notification email/sms icon.
    .btn-notification-type-container {
        display: inline-block;
        position: relative;
    }

    .btn-notification-type {
        color: $green;
        background: none !important;
        border: 0;
        padding-top: 0;

        // Add a checkmark if the notification email/sms is enabled (default).
        &:after {
            position: absolute;
            right: -15px;
            top: 18px;
            display: inline;
            @include fontawesome();
            font-size: 20px;
            color: $green;
            content: "\f00c";
        }

        &.small:after {
            font-size: 14px;
            top: 10px;
            right: -6px;
        }

        &.disabled,
        &:disabled {
            pointer-events: auto;
            cursor: pointer;
            color: $gray25;
            // Remove the checkmark if the notification email/sms is disabled.
            &:after {
                content: none;
            }
        }

        &:hover {
            color: lighten($green, 10%);
        }

        &:active {
            box-shadow: none;
        }
    }
}

.ua-mobile {
    fp-preferences-notifications {
        .btn-notification-type {
            &:hover {
                color: $green;

                &.disabled,
                &:disabled {
                    color: $gray25;
                }
            }
        }
    }
}