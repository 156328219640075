fp-emergency-testmode {

    .uib-datepicker-container {
        display: inline-block;
        padding: 5px;
        background-color: #f5f5f5;
        border: 1px solid #e3e3e3;
    }

    .uib-datepicker {
        button {
            border-width: 1px;
        }

        thead {
            button {
                width: 100%;
            }
        }
    }

    .uib-timepicker {
        .form-group {
            padding-top: 0;
        }

        button {
            margin-left: 3px;
            padding: 6.5px 12px;
            border-width: 1px;
        }
    }

    #testmode-end-time-form label {
        margin-top: 34px;
    }
}
