@import '../../content/styles/site/_values.scss';

.moving-header {
    width: 100%;
    height: 303px;
    background: url('../../content/images/Blurry-banner-image(Desktop).png') center;
    background-size: cover;

    .banner-text-container {
        margin: 0;
        width: 100%;
        position: absolute;
        padding-top: 125px;
    }

    .banner-text {
        color: white;
        font-size: 28px;
        letter-spacing: -0.38px;
        line-height: 38px;
        text-align: center;
    }
}

@media(max-width: $max-width-phone) {
    .moving-header {
        background: url('../../content/images/Blurry-banner-image(Mobile).png') center;
        background-size: cover;

        .banner-text {
            font-size: 20px;
            padding: 0 20px;
            line-height: 32px;
        }
    }
}
