
// just for phones and small tablets
@media (max-width: 991px) {
    .reason-button + div {
        margin-top: 30px;
        font-size: 12pt;
    }

    .reason-button button img {
        max-height: 75px;
        max-width: 75px;
    }

    .questionnaire-content {
        padding: 15px 0px 15px 0px;
    }

    #comparison-table {
        font-size: 9pt;
    }

    #comparison-table td > img {
        width: 25px;
        height: 25px;
    }

    #comparison-table th > img {
        max-width: 90px;
    }

    #comparison-table > tbody > tr > td:nth-of-type(1) {
        padding-left: 7px;
        padding-right: 7px;
    }

    #comparison-table th {
        padding-left: 7px;
        padding-right: 7px;
    }

}

// for devices bigger than phones or tablets
@media (min-width: 992px) {
    #reason-menu div {
        text-align: center;
    }

    .reason-button button img {
        max-height: 100px;
        max-width: 100px;
    }

    .questionnaire-content {
        padding: 50px 75px 50px 75px;
    }

    #comparison-table td > img {
        width: 35px;
        height: 35px;
    }

    #comparison-table th > img {
        max-width: 130px;
    }

    #comparison-table > tbody > tr > th:nth-of-type(1),
    #comparison-table > tbody > tr > td:nth-of-type(1) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.disabled-questionnaire-component {
    opacity: 0.3;
}

.reason-button button {
    background-color: white;
    padding: 10px;
    border: 1px solid #707070;
    border-radius: 7px;
}

.reason-button-reasons button {
    background-color: white;
    padding: 5px;
    border: 1px solid #707070;
    border-radius: 7px;
}

.reason-button-selected + div {
    font-family: 'CerebriSans-Bold', 'OpenSans-Bold';
}

.reason-button-selected button {
    background-color: #5bbb62;
    color: white;
}

.questionnaire-content {
    background-color: #f7f7f7;
}

.questionnaire-content hr {
    border: 1px solid #5bbb62;
}

.questionnaire-viewport > .row {
    margin-top: 20px;
}

.exterior-link {
    color: #3e3e3e;
    text-decoration: underline;
}

.exterior-link-disabled,
.exterior-link-disabled:hover,
.exterior-link-disabled:focus {
    color: #d7d7d7;
    cursor: not-allowed;
}

.checkbox-image input[type="checkbox"],
.checkbox-image input[type="radio"] {
    display: none;
}

.checkbox-image input + label {
    display: inline-block;
    width: 100%;
    height: 75px;
    cursor: pointer;
    padding: 5px 10px;
    border: 1px solid #707070;
}

.checkbox-image label > span {
    display: inline-block;
    top: 40%;
    position: absolute;
    transform: translateY(-50%);
    padding-left: 90px;
}

.checkbox-image input[type="checkbox"]:checked + label,
.checkbox-image input[type="radio"]:checked + label {
    border: 1px solid transparent;
    font-family: 'CerebriSans-Bold', 'OpenSans-Bold';
}

#price + label {
    background: white url(/content/images/Price_Deselected@2x.png) left no-repeat;
    background-size: contain;
    background-origin: content-box;
}

#price:checked + label {
    background: #e7f5e8 url(/content/images/Price_Selected@2x.png) left no-repeat;
    background-size: contain;
    background-origin: content-box;
}

#product + label {
    background: white url(/content/images/Product_Deselected@2x.png) left no-repeat;
    background-size: contain;
    background-origin: content-box;
}

#product:checked + label {
    background: #e7f5e8 url(/content/images/Product_Selected@2x.png) left no-repeat;
    background-size: contain;
    background-origin: content-box;
}

#contract + label {
    background: white url(/content/images/Contract_Deselected@2x.png) left no-repeat;
    background-size: contain;
    background-origin: content-box;
}

#contract:checked + label {
    background: #e7f5e8 url(/content/images/Contract_Selected@2z.png) left no-repeat;
    background-size: contain;
    background-origin: content-box;
}

#features + label {
    background: white url(/content/images/Feature_Deselected@2x.png) left no-repeat;
    background-size: contain;
    background-origin: content-box;
}

#features:checked + label {
    background: #e7f5e8 url(/content/images/Features_Selected@2x.png) left no-repeat;
    background-size: contain;
    background-origin: content-box;
}

#customer-service + label {
    background: white url(/content/images/Customer_Service_Deselected@2x.png) left no-repeat;
    background-size: contain;
    background-origin: content-box;
}

#customer-service:checked + label {
    background: #e7f5e8 url(/content/images/Customer_Service_Selected@2x.png) left no-repeat;
    background-size: contain;
    background-origin: content-box;
}

#yes + label {
    background: white url(/content/images/Yes_Deselected@2x.png) left no-repeat;
    background-size: contain;
    background-origin: content-box;
}

#yes:checked + label {
    background: #e7f5e8 url(/content/images/Yes_Selected@2x.png) left no-repeat;
    background-size: contain;
    background-origin: content-box;
}

#no + label {
    background: white url(/content/images/No_Deselected@2x.png) left no-repeat;
    background-size: contain;
    background-origin: content-box;
}

#no:checked + label {
    background: #e7f5e8 url(/content/images/No_Selected@2x.png) left no-repeat;
    background-size: contain;
    background-origin: content-box;
}

#comments {
    padding: 15px;
    width: 100%;
    height: 200px;
    border: 1px solid #adadad;
}

#comments::placeholder {
    color: #3e3e3e;
}

#comparison-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 30px;
    margin-top: 30px;
}

#comparison-table td,
#comparison-table th {
    border: 1px solid #d1d0cb;
    border-collapse: collapse;
    display: table-cell;
    text-align: center;
}

#comparison-table th {
    background-color: #e1f3db;
}

#comparison-table > tbody > tr > th:nth-of-type(1),
#comparison-table > tbody > tr > td:nth-of-type(1) {
    height: 75px;
    width: 43%;
    text-align: left;
}

#comparison-table > tbody > tr > th:not(:nth-of-type(1)),
#comparison-table > tbody > tr > td:not(:nth-of-type(1)) {
    height: 75px;
    width: 19%;
}

#comparison-table > tbody > tr > td:not(:nth-of-type(1)) {
    color: #8e8e8e;
}

#suggestions {
    padding: 15px;
    width: 100%;
    height: 250px;
    border: 1px solid #adadad;
}

#suggestions::placeholder {
    color: #3e3e3e;
    font-size: 10pt;
}

.primary-cancellation-button {
    text-transform: uppercase;
}

.primary-reason-select option {
    white-space: normal !important;
    word-wrap: break-word !important;
}

.dropdown {
    padding-bottom: 15px !important;
}


a.primary-cancellation-button {
    color: white !important;
    text-decoration: none !important;
}

.reason-button {
    margin-bottom: 15px
}

.cancel-link {
    font-weight: bold;
}

.reference-section {
    padding-top: 75px;
}

.reason-menu {
    margin-bottom: 50px;
}
