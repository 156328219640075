@import "../../../../content/styles/site/values";

.payment-card {
    position: relative;
    width: 260px;
    max-width: 90%;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 170px;
    color: $white;
    background-color: $gray85;
    border-radius: 5px;
    cursor: pointer;
    @include clearfix();

    .payment-card-autopay {
        position: absolute;
        top: 10px;
        left: 10px;

        i {
            color: $white;
        }

        span:hover {
            color: $green;
        }
    }

    .autopayOn {
        i {
            color: $green !important;
        }
    }

    .payment-card-delete {
        position: absolute;
        top: 10px;
        right: 10px;

        & button {
            border: 0;
            background-color: transparent;
        }
    }

    .payment-card-exp {
        position: absolute;
        bottom: 10px;
        left: 10px;
    }

    .payment-card-number {
        position: absolute;
        top: 50%;
        left: 10px;
    }

    .payment-card-type {
        position: absolute;
        text-transform: uppercase;
        bottom: 10px;
        right: 10px;
    }

    .payment-card-type img {
        width: 40px;
        height: auto;
    }

    .payment-card-text {
        position: absolute;
        top: 50%;
        width: 100%;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
    }

    .payment-card-delete {
        button {
            color: $white;
        }

        button:hover {
            color: $green;
        }
    }
}

.selected {
    .payment-card {
        background-color: $green;
        /*border: 2px dashed black;*/
    }
}