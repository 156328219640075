﻿@import "values";

$modal-font-size: 14px;

.modal-content {
    padding: 0;
    margin: 0;
    border: none;
    border-radius: 0;
    font-size: $modal-font-size;
    text-align: left;

    .container-fluid {
        padding: 0;
    }

    button {
        width: 115px;

        &.close-modal {
            width: unset;
        }
    }

    .modal-header {
        padding: 20px 0;
        background-color: $green;
        color: $white !important;
        text-transform: uppercase;
        text-align: center;

        button {
            margin-top: -20px;
            margin-right: 15px;
            border: 0;
            background-color: transparent;
        }

        h4 {
            color: $white;
            font-size: $modal-font-size;
        }

        h3 {
            color: $white;
            font-size: 30px;
        }

        .fa {
            color: $white;
        }

        .modal-title {
            font-weight: bold;
            margin: 0px 45px;
        }
    }

    .modal-body {
        border: none;
        padding-bottom: 20px;
        font-size: 1em;

        .col-sm-9 {
            padding-top: 5px !important;
        }

        .col-sm-3 {
            padding: 0;
        }

        .modal-sidebar {
            background-color: $gray10;

            label {
                font-weight: normal;
            }


            section {
                border-bottom: 1px solid $gray70;
                padding: 15px 15px 15px 30px;
            }

            .isSelected {
                border-left: 5px solid $green;
            }
        }
    }

    .infoSync section {
        padding: 10px 0;
    }

    .form-group {
        border-bottom: none;
    }

    .btn-toolbar {
        padding-bottom: 10px;
    }
}

.sidebar-modal-style {

    .modal-body {
        padding: 0;
    }
}

.grid {
    height: 700px;

    .payment {
        color: $error-red;
    }
}
// https://github.com/angular-ui/bootstrap/issues/1812
.ua-ios {
    .modal {
        position: absolute;
        bottom: auto;
    }
}

